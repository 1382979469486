<script lang="ts">
  import { onMount } from 'svelte';
  import '../app.css';
  import { setupI18n } from '$lib/translations/i18n';
  import toast, { Toaster } from 'svelte-french-toast';
  import { getLanguageFromLocale } from '$lib/helpers/languages';
  import GoogleAnalytics from '$lib/components/analytics/GoogleAnalytics.svelte';
  import ClarityAnalytics from '$lib/components/analytics/ClarityAnalytics.svelte';
  import { translationKeys } from '$lib/translations/translationConstants';
  import { _ } from '$lib/translations/i18n';
  import { isInStandaloneMode } from '$lib/helpers/window';
  import { onAuthStateChanged, type User } from 'firebase/auth';
  import { auth } from '$lib/auth/firebase';
  import { browser } from '$app/environment';
  import { goto } from '$app/navigation';
  import { Spinner } from 'flowbite-svelte';
  import { page } from '$app/stores';

  let authInitialized = false;

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|mac/.test(userAgent);
  };

  onMount(() => {
    if (isIos() && !isInStandaloneMode()) {
      toast($_(translationKeys.iosPwa), {
        duration: 5000,
        id: 'pwa_toast'
      });
    }

    document.documentElement.setAttribute('lang', getLanguageFromLocale());
  });

  onAuthStateChanged(auth, async (user: User | null) => {
    authInitialized = false;
    if (user && browser && $page.url && ($page.url.pathname === '/' || $page.url.pathname === '')) {
      $page.url;
      await goto('/portal');
    }
    authInitialized = true;
  });

  setupI18n({ withLocale: getLanguageFromLocale() });
</script>

<ClarityAnalytics />
<GoogleAnalytics />
<Toaster position="bottom-center" />

{#if authInitialized}
  <slot />
{:else}
  <div
    class="flex w-full h-full items-center justify-center bg:balansero-dark dark:bg-balansero-dark"
  >
    <Spinner size="14" color="purple" />
  </div>
{/if}

<style lang="scss">
  :global([role='dialog']) {
    @media (max-width: 720px) {
      padding: 0 !important;
      height: 100%;
    }
  }
  :global([role='dialog'] > div:first-child) {
    @media (max-width: 720px) {
      padding: 0 !important;
      height: 100%;
    }
  }
</style>
