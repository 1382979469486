<script lang="ts">
  import { page } from '$app/stores';

  $: {
    if (typeof gtag !== 'undefined') {
      gtag('config', import.meta.env.VITE_PUBLIC_BALANSERO_GOOGLE_ANALYTICS_TAG, {
        page_title: document.title,
        page_path: $page.url.pathname
      });
    }
  }
</script>

<svelte:head>
  <meta name="GOOGLE_TAG" content={import.meta.env.VITE_PUBLIC_BALANSERO_GOOGLE_ANALYTICS_TAG} />
  <script async id="googleTagScript"></script>
  <script>
    document.getElementById('googleTagScript').src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      document.querySelector('meta[name=GOOGLE_TAG]').content;
  </script>
  <script>
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', document.querySelector('meta[name=GOOGLE_TAG]').content);
  </script>
</svelte:head>
