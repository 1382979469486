<svelte:head>
  <meta
    name="CLARITY_TAG"
    content={import.meta.env.VITE_PUBLIC_BALANSERO_MICROSOFT_CLARITY_TOKEN}
  />
  <script type="text/javascript">
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      if (y !== undefined) {
        y.parentNode.insertBefore(t, y);
      }
    })(
      window,
      document,
      'clarity',
      'script',
      document.querySelector('meta[name=CLARITY_TAG]').content
    );
  </script>
</svelte:head>
